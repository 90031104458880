<template>
  <div class="popup-stories">
    <div class="popup-stories__wrapper"
         v-touch:swipe.left="nextStory"
         v-touch:swipe.right="previewStory">
      <HeroSliderButton v-if="$laptop"
                        v-on:click.native="previewStory"
                        :params="{suffix: 'preview'}"/>
      <transition name="fade"
                  mode="out-in">
        <Column :key="current"
                class="popup-stories__current"
                justify="end">
          <div class="steps">
            <div class="steps__wrapper"
                 :style="{'gridTemplateColumns': `repeat(${stories.imgs.length}, 1fr)`}">
              <div class="steps__step"
                   v-for="(step, index) in stories.imgs"
                   :class="index === current ? 'steps__active' : ''"
                   :key="step.id"></div>
            </div>
          </div>
          <img class="popup-stories__current-image"
               :src="image"
               alt>
          <h6 class="popup-stories__current-title">{{title}}</h6>
          <p class="popup-stories__current-description">{{description}}</p>
        </Column>
      </transition>
      <HeroSliderButton v-if="$laptop"
                        v-on:click.native="nextStory"
                        :params="{suffix: 'next'}"/>
      <transition name="fade"
                  mode="out-in">
        <img :key="current"
             v-if="!!leftImage && $laptop"
             class="popup-stories__left-image"
             :src="leftImage"/>
      </transition>
      <transition name="fade"
                  mode="out-in">
        <img :key="current"
             v-if="!!rightImage && $laptop"
             class="popup-stories__right-image"
             :src="rightImage"/>
      </transition>
    </div>
  </div>
</template>

<script>
import HeroSliderButton from '../HeroSlider/HeroSliderButton.vue';

export default {
  name: 'PopupStories',
  components: { HeroSliderButton },
  data() {
    return {
      current: 0,
    };
  },
  computed: {
    stories() {
      return this?.$popup?.additions;
    },
    story() {
      return this?.$popup?.additions?.imgs[this.current];
    },
    image() {
      return this?.story?.image;
    },
    title() {
      return this?.story?.title;
    },
    description() {
      return this?.story?.description;
    },
    leftImage() {
      return this?.$popup?.additions?.imgs[this.current - 1]?.image || '';
    },
    rightImage() {
      return this?.$popup?.additions?.imgs[this.current + 1]?.image || '';
    },
  },
  methods: {
    nextStory() {
      if (this.current < this?.$popup?.additions?.imgs?.length - 1) this.current += 1;
    },
    previewStory() {
      if (this.current > 0) this.current -= 1;
    },
  },
};
</script>
